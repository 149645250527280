import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
// import Helmet from "react-helmet"
import loadable from "@loadable/component"
import { useStaticQuery, graphql } from "gatsby"
import Facebook from "./Facebook"
import Twitter from "./Twitter"
import TopMenu from "../../../static/devMenu.json"
import { addsLinks } from "../../util/conditionalLinks"
const Helmet = loadable(() => import("react-helmet"))

const SEO = ({
  title,
  desc,
  banner,
  keyword,
  pathname,
  article,
  node,
  dateModified,
  datePublished,
  ogurl,
  ogtitle,
  ogdesc,
  ogbanner,
  tweettitle,
  tweetdesc,
  tweetbanner,
  metaRobotsNoindex,
  canonical,
  schemas = []
}) => {
  const [current, setCurrent] = useState()
  useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])
  const data = useStaticQuery(graphql`
  query HeaderQuery {
     wp {
      seo {
        schema {
          companyName
          companyOrPerson
          inLanguage
          siteName
          siteUrl
          wordpressSiteName
        }
        social {
          facebook {
            url
          }
          instagram {
            url
          }
          linkedIn {
            url
          }
          twitter {
            cardType
            username
          }
          youTube {
            url
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        author {
          name
          photo
        }
        contact {
          phone_no
        }
        title
        description
        siteUrl
        headline
        siteLanguage
      }
    }
  }
`)
  const { wp, site } = data
  // eslint-disable-next-line no-unused-vars
  const { schema, social } = wp.seo
  const { siteMetadata } = site

  const seo = {
    title: title || siteMetadata.title,
    description: desc || siteMetadata.description,
    keyword: keyword || social.companyName,
    image: banner || "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/05/New-Project.png",
    url: `${siteMetadata.siteUrl}${pathname || ""}`,
    ogurl: `${siteMetadata.siteUrl}${ogurl === "https://dev.webmobtech.biz/wordpress/" ? "/" : ogurl || ""}`,
    ogtitle: ogtitle || title || siteMetadata.title,
    ogdescription: ogdesc || desc || siteMetadata.description,
    ogimage: ogbanner || banner || "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/05/New-Project.png",
    tweettitle: tweettitle || title || siteMetadata.title,
    tweetdescription: tweetdesc || desc || siteMetadata.description,
    tweetimage: tweetbanner || banner || "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/05/New-Project.png",
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "WebMob Technologies",
    url: "https://webmobtech.com/",
    description: "Web & App Development Company in US | Mobile App Development",
    mainEntityOfPage: "https://webmobtech.com/",
    telephone: siteMetadata.contact.phone_no,
    sameAs: [social.facebook.url, social.instagram.url, social.linkedIn.url, social.youTube.url, "https://twitter.com/webmobtech"],
    keyword: social.companyName,
    image: {
      "@type": "ImageObject",
      url: "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/02/WMT_Horizontal_Original.png",
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "202, Kalasagar Shopping Hub, Sattadhar Cross Rd, Ghatlodiya, 380061",
      addressLocality: "Ahmedabad",
      addressRegion: "Gujarat",
      postalCode: "411021",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.4",
      reviewCount: "1257"
    },
  }

  const itemListElement =
  {
    "@context": "http://schema.org",
    "@type": "ItemList",
    itemListElement:
      TopMenu?.map(i => (
        {
          "@type": "SiteNavigationElement",
          position: i.id,
          name: i.main_label,
          url: `${siteMetadata.siteUrl}${i.main_url}`,
        }
      ),
      )

  }

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Organization",
      name: "WebMob Technologies",
      url: "https://webmobtech.com/",
      description: "Web & App Development Company in US | Mobile App Development",
      mainEntityOfPage: "https://webmobtech.com/",
      telephone: siteMetadata.contact.phone_no,
      sameAs: [social.facebook.url, social.instagram.url, social.linkedIn.url, social.youTube.url, "https://twitter.com/webmobtech"],
      keyword: social.companyName,
      image: {
        "@type": "ImageObject",
        url: "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/02/WMT_Horizontal_Original.png",
      },
      address: {
        "@type": "PostalAddress",
        streetAddress: "202, Kalasagar Shopping Hub, Sattadhar Cross Rd, Ghatlodiya",
        addressLocality: "Ahmedabad",
        addressRegion: "Gujarat",
        postalCode: "380061",
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.4",
        reviewCount: "1257"
      },
    }
  }
  return (
    <React.Fragment>
      <Helmet
        // htmlAttributes={{ lang }} // canonical test
        title={seo.title}
        link={
          canonical
            ? [{ rel: 'canonical', key: canonical, href: canonical }]
            : []
        }  // canonical test
      >
        <html lang={siteMetadata.siteLanguage} />
        <link rel="canonical" key={canonical} href={canonical} data-react-helmet="true"></link>
        <meta name="title" content={seo?.title} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="keywords" content={seo.keyword} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {current && addsLinks.includes(current) &&
          <meta name="robots" content={metaRobotsNoindex} />
        }
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          current==='/' ?
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
          : null
        )}
        {article && (
          current==='/' ?
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
          : null
        )}
        {
          schemas && schemas.map((val,index)=>{
            return <script type="application/ld+json">
            {val?.jsonldSchema}
          </script>
          })
        }
        <script type="application/ld+json">{JSON.stringify(itemListElement)}</script>
        <link rel="preload" href="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/03/For_Cookies.png" as="image" media="(max-width: 500px)"></link>
      </Helmet>
      <Facebook
        desc={seo.ogdescription}
        keyword={seo.keyword}
        image={seo.ogimage}
        title={seo.ogtitle}
        type={article ? "article" : "website"}
        url={seo.ogurl}
        locale="en-US"
        name={social.companyName}
      />
      <Twitter
        title={seo.tweettitle}
        keyword={seo.keyword}
        image={seo.tweetimage}
        desc={seo.tweetdescription}
        username={social.companyName}
      />
    </React.Fragment>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  keyword: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  canonical: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  keyword: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
  datePublished: null,
  dateModified: null,
  canonical: null,
}
