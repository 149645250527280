// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
// import ScrollUpButton from "react-scroll-up-button"
import loadable from "@loadable/component"
import CookieConsent from "react-cookie-consent"
import Footer from "./components/Footer"
import Header from "./components/HeaderV2"
import Poptin from "./components/blocks/PopUpForm"
import AddsPopup from "./components/blocks/Forms/AddsPopup"
import "./layout.css"
import AOS from "aos"
import "aos/dist/aos.css"
import { banPopUp, addsLinks, nofollowLink, hireLink} from "./util/conditionalLinks"
import { Helmet } from "react-helmet"
// import Hubspote from "./components/Hubspote"
// import { Helmet } from "react-helmet"
// import PopUpGitex from "./components/blocks/Forms/PopupForm"
// import StickyHeader from "./components/StickyHeader"
// import { Helmet } from "react-helmet"
const ScrollToTopButton = loadable(() => import("./components/ScrollToTopButton"))
const Hubspote = loadable(() => import("./components/Hubspote"))

// import ScrollToTopButton from "./components/ScrollToTopButton"

const Layout = ({ children, location }) => {
  const [popup, setPopup] = useState(false)
  // const [gitexPopup, setGitexPopup] = useState(false)
  const [addPopup, setAddPopup] = useState(false)
  const [key, setKey] = useState(undefined)
  const [isDesktop, setIsDesktop] = useState();
  const [isHubSpot, setIsHubSpot] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 991.98);
  };

  const isFalse = value => {
    setPopup(value)
  }
  useEffect(() => {
    setTimeout(() => {
      temp()
    }, 5000)
  }, [])

  const temp = () => {
    const K = JSON.parse(sessionStorage.getItem("popUpKey"))
    setKey(K)
    const startTime = JSON.parse(sessionStorage.getItem("startTime"))
    const First = JSON.parse(sessionStorage.getItem("First"))
    if (First !== null && startTime > 0) {
      setTimeout(() => {
        K ? setPopup(true) : setPopup(false)
      }, First * 1000)
    }
  }

  useEffect(() => {
    const AOS = require("aos")
    AOS.init({
      once: false,
      offset: 100,
      duration: 700,
      easing: "ease-out-quad",
    })
  }, [])

  function addPopUp() {
    sessionStorage.setItem('popUpKey', true)
    sessionStorage.setItem('popUpCount', 0)
    sessionStorage.setItem('startTime', 90);
    sessionStorage.setItem('GitexForm', false);
  }

  // window.addEventListener("load", addPopUp)
  function timerFun() {
    var AA = sessionStorage.getItem('startTime')
    window.setInterval(function () {
      if (AA > 0) {
        AA -= 1;
        sessionStorage.setItem('First', AA);
      }
    }, 1000);
  }

  // window.addEventListener("load", timer)
  useEffect(() => {
    if (AOS) {
      setTimeout(() => {
        AOS.refresh()
      }, 500)
    }
  })

  const [current, setCurrent] = useState()
  const [timer, setTimer] = useState(false)
  setTimeout(() => {
    setTimer(true)
  }, 2000)

  useEffect(() => {
    setCurrent(window.location.pathname)
    addPopUp()
    timerFun()
  }, [])

  const handleHubspot = () => {
    setIsHubSpot(!isHubSpot)
    document.body.style.overflow = 'hidden';
  }
  return (
    <>
      {isHubSpot&&
        <Hubspote handleHubspot={setIsHubSpot} isHubSpot={isHubSpot} />
      }
      {
        nofollowLink.includes(current)&&(
          <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        )
      }
      {addsLinks.includes(current) ? (
        <>
          {/* Adds page Button Get a call back */}
          {/* <div
            className="fixed z-10 rounded-md transform cursor-pointer -right-12 -rotate-90 top-32"
            role="button"
            onClick={() => {
              setAddPopup(true)
            }}
            aria-hidden="true"
          >
            <div
              className="py-2 px-4 rounded-md "
              style={{ backgroundColor: "#ffd333" }}
            >
              <p className="text-sm font-medium text-shark-600">Get a call back</p>
            </div>
          </div> */}
          {/* Adds pages Whatsapp sticky */}
          {/* <div
            className="fixed z-10 rounded-md transform cursor-pointer lg:right-8 right-4 bottom-52"
          >
            <a target="_blank" rel="noreferrer"
              href="https://api.whatsapp.com/send?l=en&text=Hi%20I%20am%20interested%20in%20one%20of%20your%20service%20at%20WebMob%20Technologies&phone=+91 7043866892"
            ><div className="h-12 w-12 rounded-full bg-green-500 flex justify-center items-center"><img src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/whatsapp.png" alt="Whatsapp" /></div></a>
          </div> */}
        </>
      ) : (
        <div className="fixed w-full z-20 top-0">
          {/* {USA2023.includes(current) ? "" : <StickyHeader />} */}
          <Header />
        </div>
      )}
      <div
        className="fixed z-20 rounded-md transform cursor-pointer lg:right-7 md:right-7 right-3.5 bottom-40 md:bottom-40 "
      >
        <a target="_blank" rel="noreferrer"
          href="https://wa.me/917043866892?text=Hello%20%2C%20I%20have%20a%20questions%20about%20your%20services.%20Can%20you%20please%20help%20me%3F"
        ><div className="h-14 w-14 rounded-full bg-transparent flex justify-center items-center"><img src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/03/Whats-App-112.webp" alt="Whatsapp" className="" height='112' width='112'/></div></a>
      </div>
      <div className="static">
        {/* <ScrollUpButton
          ContainerClassName="scrolltotop"
          TransitionClassName="AnyClassForTransition"
          className="scrolltotop"
        /> */}
        <ScrollToTopButton />
      </div>
      {/* {gitexPopup && (
        <>
          <PopUpGitex setGitexPopup={setGitexPopup} />
        </>
      )} */}
      {addPopup && (
        <>
          <AddsPopup setAddPopup={setAddPopup} />
        </>
      )}
      {popup && key && !banPopUp.includes(current) && !isHubSpot && !hireLink.includes(current) ? (
        <Poptin isFalse={isFalse} isTemp={temp} />
      ) : (
        ""
      )}

      {/* {gitexLink.includes(current) ? (
        <div
          className="fixed z-10 rounded-md transform cursor-pointer -right-32 -rotate-90 md:top-1/2 top-72"
          onClick={() => {
            setGitexPopup(true)
          }}
        >
          <div
            className="py-2 px-4 rounded-md "
            style={{ backgroundColor: "#ffd333" }}
          >
            <p className="text-sm font-medium text-shark-600 ">Get a Free GITEX Pass worth AED 500</p>
          </div>
        </div>
      ) : (
        ""
      )} */}
      <main id="root">{children}</main> {/*do it className="mt-12" on gitex page */}
      <>
        <div
          className="fixed z-10 rounded-md transform cursor-pointer -right-14 -rotate-90 top-44 w-fit"
          role="button"
          onClick={handleHubspot}
          aria-hidden="true"
        >
          <div
            className="py-2 px-4 rounded-md "
            style={{ backgroundColor: "#ffd333" }}
          >
            <p className="text-sm font-medium text-shark-600">Book a Meeting</p>
          </div>
        </div>
      </>
      {isDesktop && <CookieConsent
        location="bottom"
        cookieName="wmtCookie"
        containerClasses="cookie_bar "
        buttonText="Got it!"
        buttonClasses="accept_btn self-center"
        declineButtonClasses="decline_btn self-center ml-0 mr-auto lg:mr-3"
        flipButtons
        onAccept={() => {
          window["ga-disable-UA-73379983-2"] = false
        }}
        onDecline={() => {
          window["ga-disable-UA-73379983-2"] = true
          window.location.reload()
        }}
        expires={150}
      >
        <div>
          We use cookies to personalize our service and to improve your
          experience on the website and its subdomains. We also use this
          information for analytics.
        </div>
      </CookieConsent>}
      {timer && <Footer />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
